$breadcrumb-height: 56px !default;
$breadcrumb-item-gap: 1rem !default;

$breadcrumb-scroll-bg: (
    '': $background,
    '.filter-page-breadcrumb--panel': $grey-lighter,
) !default;

$breadcrumb-color: $brownish-grey !default;
$breadcrumb-color-inverse: $white !default;

$breadcrumb-active-color: lighten($breadcrumb-color, 0%) !default;
$breadcrumb-active-color-inverse: darken($breadcrumb-color-inverse, 0%) !default;

$breadcrumb-bg: $white !default;
$breadcrumb-bg-inverse: $black !default;

$breadcrumb-border-color: transparent !default;

// These indents are overridden for each site.
$breadcrumb-indent-mobile: 0 !default;
$breadcrumb-indent-mobile-small: 20px !default;
$breadcrumb-indent-tablet: 20px !default;
$breadcrumb-indent-desktop: 15px !default;
$breadcrumb-indent-widescreen: 25px !default;
$breadcrumb-indent-fullhd: 27px !default;
$breadcrumb-indent-fullwidth: 27px !default;

@mixin breadcrumb-reset {
    padding: 0;
    margin: 0;
    list-style: none;
}

@mixin breadcrumb-scroll($direction) {
    @include scroll-fade($breadcrumb-scroll-bg, $direction);
    @include until($desktop) {
        @include scroll-fade-bg($header-bg-color, $direction);
    }
}

.breadcrumb {
    position: relative;
    pointer-events: none;

    &--scroll-fade-left {
        &::before {
            @include breadcrumb-scroll(left);
        }
    }

    &--scroll-fade-right {
        &::after {
            @include breadcrumb-scroll(right);
        }
    }

    &--scroll-fade-both {
        &::before {
            @include breadcrumb-scroll(left);
        }

        &::after {
            @include breadcrumb-scroll(right);
        }
    }

    &__background {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: initial;
        justify-content: center;
        height: $breadcrumb-height;
        width: 100%;

        &::before {
            position: absolute;
            width: 100vw;
            height: 100%;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
        }

        &::after {
            content: '';
            position: absolute;
            left: -1rem;
            right: -1rem;
            top: 0;
            border-top: 1px solid $breadcrumb-border-color;

            @include from($desktop) {
                left: $breadcrumb-indent-desktop;
                right: $breadcrumb-indent-desktop;
            }
            @include from($widescreen) {
                left: $breadcrumb-indent-widescreen;
                right: $breadcrumb-indent-widescreen;
            }
            @include from($fullhd) {
                left: $breadcrumb-indent-fullhd;
                right: $breadcrumb-indent-fullhd;
            }
            @include from($fullwidth) {
                left: $breadcrumb-indent-fullwidth;
                right: $breadcrumb-indent-fullwidth;
            }
        }

        @include from($desktop) {
            &--dark {
                &::before {
                    content: '';
                    background: linear-gradient(180deg, rgba($breadcrumb-bg-inverse, 0.6) 0%, rgba($breadcrumb-bg, 0) 100%);
                }
            }

            &--light {
                &::before {
                    content: '';
                    background: linear-gradient(180deg, rgba($breadcrumb-bg, 0.6) 0%, rgba($breadcrumb-bg-inverse, 0) 100%);
                }
            }

            &--overlaid {
                justify-content: flex-start;
                margin-bottom: -(px-to-rem($breadcrumb-height) + $grid-first-row-padding-top);
                z-index: 5;

                &::after {
                    border-color: transparent;
                }
            }
        }
    }

    &__list {
        @include breadcrumb-reset;
        @include typeset(x-small);

        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        height: 4rem;

        white-space: nowrap;
        gap: $breadcrumb-item-gap;
        -webkit-overflow-scrolling: touch;
        max-width: $fullwidth;

        padding-left: $breadcrumb-indent-mobile;
        @include from($mobile-small) {
            padding-left: $breadcrumb-indent-mobile-small;
        }
        @include from($tablet) {
            padding-left: $breadcrumb-indent-tablet;
        }
        @include from($desktop) {
            padding-left: $breadcrumb-indent-desktop;
        }
        @include from($widescreen) {
            padding-left: $breadcrumb-indent-widescreen;
        }
        @include from($fullhd) {
            padding-left: $breadcrumb-indent-fullhd;
        }
        @include from($fullwidth) {
            padding-left: $breadcrumb-indent-fullwidth;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__item {
        @include breadcrumb-reset;
    }

    &__item-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        gap: $breadcrumb-item-gap;
        color: $breadcrumb-color;
        pointer-events: auto;

        span {
            height: 17px;
        }

        &:focus,
        &:hover {
            color: inherit;

            span {
                text-decoration: underline;
                color: inherit;
            }
        }

        &--active,
        &--active:focus,
        &--active:hover {
            color: $breadcrumb-active-color;
        }

        @include from($desktop) {
            &--inverse,
            &--inverse:focus,
            &--inverse:hover {
                color: $breadcrumb-color-inverse;
            }

            &--active-inverse,
            &--active-inverse:focus,
            &--active-inverse:hover {
                color: $breadcrumb-active-color-inverse;
            }
        }
    }

    svg-icon-sprite,
    svg {
        font-size: 0;
    }
}
