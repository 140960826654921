$header-height-mobile: 6.4rem;
$header-height-desktop: 10rem;

/****** MEGA MENU  *******/
$mega-model-container-space-top: 3.1rem;
$mega-menu-border-top-space: 3rem;

/****** MEGA SIDE MENU (SHORTCUTS)  *******/
$mega-sidemenu-link-color: $brownish-grey;
$mega-sidemenu-label-size-key: x-small;
$mega-sidemenu-label-family-key: bold;
$mega-sidemenu-is-uppercase: true;

/****** NAVIGATION *******/
$mega-sidemenu-margin-top: 0.2rem;
$mega-sidemenu-link-padding-top: 0.3rem;
$mega-sidemenu-link-padding-bottom: 0.2rem;
$mega-sidemenu-link-icon-position: 10px;
$mega-sidemenu-link-text-position: 10px;

/****** MOBILE MENU *******/
$mobile-header-spacing-top: 3.2rem;
$mobile-header-spacing-bottom: 1rem;

$mobile-menu-link-color: $black-bis;
$mobile-menu-goto-color: $black;
$mobile-menu-goto-size-key: small;
$mobile-menu-goto-family-key: bold;
$mobile-menu-goto-padding-bottom: 1.6rem;

$mobile-menu-text-color: $black;

$mobile-menu-title-size-key: small;
$mobile-menu-title-family-key: bold;
$mobile-menu-title-line-height-key: large;
$mobile-menu-title-color: $black;
$mobile-menu-title-transform: uppercase;

$mobile-holder-horizontal-padding: $gutter;

$mobile-nav-item-padding-left: 0;
$mobile-nav-item-padding-right: 0;
$mobile-nav-item-padding-top: 1.7rem;
$mobile-nav-item-padding-bottom: 2.3rem;

$mobile-chevron-x-cord: 4px;
$mobile-chevron-y-cord: 4px;

$mobile-nav-back-bg: transparent;
$mobile-nav-back-border-top: transparent;
$mobile-nav-back-border-bottom: transparent;
$mobile-nav-back-size-key: small;
$mobile-nav-back-color: $primary;
$mobile-nav-back-bottom: 1.6rem;

$mobile-breadcrumb-margin-left: -1.2rem;
$mobile-breadcrumb-padding-top: 0.6rem;

$mobile-links-color: $grey-darker;
$mobile-links-horizontal-padding: 0;
$mobile-links-vertical-padding: 2rem;
$mobile-links-icon-padding: 0.3rem;

$mobile-model-price-color: $grey-darker;
$mobile-model-text-size-key: small;
$mobile-model-space: 0.7rem;
$mobile-model-weight: normal;
$mobile-model-size-key: base;

/****** ICONS *******/
$icon-cross-size-small: 1.5rem;
$icon-cross-size-large: 3rem;
$icon-tag-car-padding-vertical: 0.2rem;
$icon-tag-car-padding-horizontal: 0.4rem;
$icon-tag-right: -1.4rem;

/****** GRID *******/
$grid-first-row-padding-top: $gutter;
$grid-first-row-padding-touch-top: $gutter-small;
$dark-theme-bg: $blue;
$dark-theme-text: $white;
$light-theme-bg: $grey-lighter;
$light-theme-text: $text;
$grid-banner-row-has-bg: false;

/****** HEADLINE SPOT *******/
$light-subtitle-color: $grey-dark;
$headline-subtitle-desktop-letter-space-key: normal;
$headline-subtitle-touch-letter-space-key: normal;
$headline-subtitle-padding-touch: 0.5rem;
$headline-background-color: $grey;
$headline-background-color-light: $grey;

/****** GRID *******/
$container-padding-desktop: $gutter;
$container-padding-touch: $gutter-small;

/****** UTIL *******/
$border: $grey-light;

/****** FORM FIELD (INPUT, SELECT, LABEL ) *******/
$label-color: $text;
$label-text-transform: uppercase;
$label-font-size: $font-size-x-small;
$label-font-family: $font-family-base-bold;
$help-text-size: sub;
$field-size-key: sub;
$input-background-color: $grey;

/****** MAP SPOT *******/
$map-address-color: $grey-dark;
$map-address-line-height: 1.5;
$map-address-padding-top: 0.6rem;
$map-address-padding-bottom: 0.6rem;
$map-name-size-key: base;

$map-opening-title-size-key: x-small;
$map-opening-title-transform: uppercase;
$map-opening-title-color: $black-bis;
$map-opening-title-family-key: bold;
$map-opening-title-margin-bottom: 0;
$map-opening-title-padding-top: 2rem;
$map-opening-title-margin-bottom: 0;

$map-phone-title-display: block;
$map-phone-title-family-key: bold;
$map-phone-title-size-key: x-small;
$map-phone-title-transform: uppercase;
$map-phone-title-color: $black-bis;
$map-phone-title-margin-bottom: 0;
$map-phone-title-padding-top: 2rem;
$map-phone-title-padding-bottom: 0.6rem;
$map-toggle-button-color: $grey-darker;

/****** HEADLINE SPOT *******/
$default-subtitle-color: $grey-dark;
$headline-subtitle-touch-font-key: medium;
$headline-subtitle-desktop-font-key: medium;

/****** BUTTONS *******/
$button-primary-hover-bg: $blue-dark;
$button-filter-color: $grey-dark;
// Filled button in light row theme
$button-filled-light-text-color: $white;
$button-filled-light-bg: $primary;
$button-filled-light-bg-hover: $blue-dark;
$button-filled-light-text-hover-color: $white;
$button-filled-light-border-color: $primary;
$button-filled-light-border-hover-color: $blue-dark;
// Filled button in dark row theme
$button-filled-dark-text-color: $white;
$button-filled-dark-bg: $primary;
$button-filled-dark-bg-hover: $blue-dark;
$button-filled-dark-text-hover-color: $white;
$button-filled-dark-border-color: $primary;
$button-filled-dark-border-hover-color: $blue-dark;
// button outline in default row theme
$button-outline-bg: transparent;
$button-outline-bg-hover: $text;
$button-outline-text-color: $black;
$button-outline-text-hover-color: $white;
$button-outline-border-color: $grey-light;
// Outline button in light row theme
$button-outline-light-text-color: $white;
$button-outline-light-text-hover-color: $white;
$button-outline-light-bg: $primary;
$button-outline-light-bg-hover: $blue-dark;
$button-outline-light-border-color: $blue-dark;

/****** FONT *******/
$font-smoothing: auto;

/****** SEARCH *******/
$search-description-size: $font-size-sub;
$search-title-text-transform: uppercase;
$search-title-font-size: 1.3rem;

/****** ALL MODELS (SLIME) *******/
$filter-slime-border-color: $grey-light;

/****** LEGAL *******/
$legal-font-size-small: $font-size-x-small;
$legal-font-size: $font-size-x-small;
$legal-color: $grey-dark;

/****** LINK TAG  *******/
$link-default-color: $primary;
$link-default-color-hover: $hover;

/****** MODEL PAGE *******/
$sub-nav-header-bg: $white;
$sub-nav-header-color: $text;
$model-nav-active-color: $primary;
$sub-nav-header-border-width: 1px;
$model-page-title-is-small: true;
$model-nav-item-size-key: navigation;
$model-page-header-height: 8rem;
$model-nav-padding-vertical: 0;
$model-page-header-padding-bottom: 0;
$model-page-header-inner-padding: $gutter;
$model-page-header-nav-padding-bottom: 0;
$model-page-header-actions-padding-bottom: 0;

$model-list-item-size: medium;
$model-list-item-price-padding: 0.3rem;
$model-list-item-title-size: large;

/****** PRODUCT DETAILS *******/
$product-details-description-height-desktop: 10rem;
$product-details-description-height-touch: 8.8rem;
$product-details-border: #e6e6e6;

$slime-border-color: $border;

$price-price-menu-weight-key: normal;
$price-price-menu-family-key: bold;
$price-price-font-size: 1.3rem;
$product-details-disclaimer-color: $grey-dark;

/****** SPECIFICATIONS *******/
$specification-input-color: $black;
$mobile-chevron-back-icon-color: $primary;

/****** CTA SPOT *******/
$cta-divider-color: $grey-light;

/****** BUTTONS *******/
// Filled button in dark row theme
$button-filled-dark-text-color: $primary;
$button-filled-dark-text-hover-color: $black;
$button-filled-dark-bg: $white;
$button-filled-dark-bg-hover: $grey-light;
$button-filled-dark-border-color: $primary;
$button-filled-dark-border-hover-color: $primary;

$product-search-input-bg: $white;

/****** BREADCRUMB INDENTS *******/
$breadcrumb-indent-mobile: 5px;
$breadcrumb-indent-mobile-small: 5px;
$breadcrumb-indent-tablet: 5px;
$breadcrumb-indent-desktop: 0;
$breadcrumb-indent-widescreen: 0;
$breadcrumb-indent-fullhd: 0;
$breadcrumb-indent-fullwidth: 0;
