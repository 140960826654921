$dp-width: 30rem;
$dp-height: 26rem;
$dp-bg: $white;
$dp-border-color: $primary !default;
$dp-header-height: 5rem;
$dp-header-bg: $white;
$dp-header-color: $primary !default;

ngb-datepicker {
    &.dropdown-menu {
        z-index: 9;
        min-height: $dp-height;
        min-width: $dp-width;
        width: 100%;
        background-color: $white;
        border: $control-border-width solid $dp-border-color;

        .ngb-dp-header {
            background-color: $dp-header-bg;
            color: $dp-header-color;
            border-bottom: $control-border-width solid $dp-border-color;

            ngb-datepicker-navigation {
                height: $dp-header-height;
            }
        }

        ngb-datepicker-navigation-select > {
            .custom-select {
                @extend .input;
                min-width: 11rem;
                height: $input-height - 0.9rem;
                padding-top: 0;
                padding-bottom: 0;
                margin: 0 0.5rem;
                background-color: $input-background-color;
                background-image: url('/assets/icons/select-arrow.svg');
                background-repeat: no-repeat;
                border-radius: 0;
                appearance: none;
                background-position-x: calc(100% - 1.7rem);
                background-position-y: center;

                @include tablet {
                    min-width: 17rem;
                }

                + .custom-select {
                    min-width: 11rem;
                }
            }
        }

        .ngb-dp-weekdays {
            border-bottom: 1px solid $border;
        }

        .ngb-dp-day,
        .ngb-dp-week-number,
        .ngb-dp-weekday {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 15%;
            height: 3.8rem;
            color: $black;
            font-size: map-get($font-sizes, small);

            > div {
                width: 3.6rem;
                height: 3.6rem;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $white;
                transition: background-color 60ms ease-in-out;
            }

            &:hover {
                > div {
                    background-color: $grey-darker;
                    color: $white;
                }
            }

            .active {
                background-color: $grey-darker;
                color: $white;
            }

            .bg-primary {
                background-color: $primary;
                color: $white;
            }
        }

        .ngb-dp-day {
            &.disabled {
                cursor: not-allowed;
                text-decoration: line-through;
            }

            .outside {
                opacity: 0.4;
            }
        }

        .ngb-dp-weekday {
            font-family: map-get($font-families, medium);
            font-style: normal;
        }

        .ngb-dp-month {
            width: 100%;
        }

        .ngb-dp-arrow {
            width: auto;
            height: auto;
        }

        .ngb-dp-arrow-btn {
            padding: 1.5rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover,
            &:focus {
                outline-style: auto;
                outline-width: 1px;
            }
        }

        [ngbDatepickerDayView] {
            width: auto;
        }
    }
}
