$carousel-slide-bg: $white !default;
$carousel-button-bg: $white !default;
$carousel-button-color-primary: $black !default;
$carousel-button-color-secondary: $primary !default;
$carousel-button-color-tertiary: $black !default;
$carousel-button-bg-tertiary: #f6f6f6 !default;
$carousel-button-color-tertiary-border: #f2f2f2 !default;
$carousel-bullet-bg: $white !default;
$carousel-more-color: $white !default;
$carousel-more-bg: $black !default;
$carousel-more-size-key: medium !default;

$swiper-button-tight-space: 2rem !default;
$swiper-button-tight-prev: 1.6% !default;
$swiper-button-tight-next: 2rem !default;

$container-offset: (2 * $gap) !default;
$container-max-width: $fullhd !default;

:root {
    --swiper-theme-color: #{$primary};
    --swiper-navigation-color: #{$carousel-button-color-primary};
}

.hero-slider-spot {
    @include is-full;

    user-select: none;
    overflow: hidden;

    &__pause-button {
        position: relative;
        z-index: 99;
        cursor: pointer;
        border-radius: 50%;
        width: $swiper-button-size-base;
        height: $swiper-button-size-base;
        background: $carousel-button-bg;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $carousel-button-color-primary;

        @media (max-width: 768px) {
            width: $swiper-button-size-small;
            height: $swiper-button-size-small;
        }

        svg {
            display: flex;
        }
    }

    // this overrides the default swiper-button styling
    .swiper-button-prev,
    .swiper-button-next {
        position: relative;
        top: auto;
        bottom: auto;
        left: 0;
        right: 0;
        margin: 0;

        &.swiper-button-disabled {
            display: flex;
            opacity: 0.5;
        }
    }

    .extended-hero-spot__container {
        min-height: 0; // fixes aspect ratio so it does not grow, to ensure slider nav position
    }

    .extended-hero-spot__content {
        padding-bottom: 7rem; // Avoids CTA collision with slider nav

        @include tablet {
            padding-bottom: 9rem;
        }
    }

    &__carousel-nav-container {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        aspect-ratio: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        pointer-events: none; // just to make sure this overlay doesn't catch any events

        @include tablet {
            aspect-ratio: 21 / 9;
        }
    }

    &__carousel-nav {
        max-width: calc(100% - 2rem);
        position: relative;
        bottom: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        pointer-events: all;

        @include from($mobile-large) {
            gap: 16px;
        }

        @include tablet {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 2rem;
            height: var(--swiper-navigation-size);
            z-index: 10; // Ensure navigation is on top

            > * {
                position: relative;
                top: 0;
                left: 0;
                right: 0;
            }
        }
    }

    &__pagination {
        display: flex;
        gap: 6px;
        position: relative;
        bottom: 0;
        height: fit-content;
        z-index: 99;
        list-style: none;

        @include tablet {
            gap: 12px;
            margin: 0 6px;
        }

        .swiper-pagination-bullet {
            width: 15px;
            height: 15px;
            background-color: #{$carousel-bullet-bg};
            opacity: 0.5;
            border-radius: 50%;
            cursor: pointer;
            transition:
                background-color 0.3s,
                opacity 0.3s;
            padding: 0;
        }

        .swiper-pagination-bullet-active {
            background-color: #{$carousel-bullet-bg};
            opacity: 1;
        }

        .swiper-pagination-bullet::before {
            display: none;
        }
    }
}
