$hero-padding-mobile-horizontal: 1rem !default;
$hero-padding-mobile-large-horizontal: 2rem !default;
$hero-padding-desktop-horizontal: 2.5rem !default;
$hero-color: $white !default;
$hero-legal-color: $white !default;
$hero-title-long-word: 9vw !default;
$hero-title-text-transform: none !default;
$hero-title-letter-spacing: large !default;
$hero-title-font-weight: normal !default;
$hero-title-margin-bottom: 1rem !default;
$hero-title-word-break: normal !default;
$hero-title-default-size-key: largestMobile !default;
$hero-title-desktop-size-key: largest !default;
$hero-title-fullhd-size-key: huge !default;
$hero-title-family-key: medium !default;
$hero-title-line-height-key: small !default;
$hero-subtitle-size-key: larger !default;
$hero-subtitle-family-key: medium !default;
$hero-subtitle-line-height-key: secondary !default;
$hero-subtitle-weight-key: normal !default;
$hero-subtitle-text-transform: none !default;
$hero-subtitle-letter-space-key: small !default;
$hero-subtitle-margin-bottom: 3rem !default;
$hero-content-position: center !default;
$hero-content-bg: transparent !default;
$hero-content-max-width: 82rem !default;
$hero-content-padding: 1.9rem 2.5rem 2.1rem !default;
$hero-content-margin-left: 6rem !default;

.hero-spot {
    position: relative;
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr 8rem 1fr;
    color: $hero-color;
    width: 100%;

    @include desktop {
        grid-template-rows: 1fr 6rem;
    }

    &:hover {
        .button.is-hero {
            background-color: $button-hero-hover-bg;
            color: $button-hero-text-hover-color;
            border-color: $button-hero-border-hover-color;
        }
    }

    &__link {
        position: absolute;
        inset: 0;
        z-index: 3;
        opacity: 0;
    }

    &__media-container {
        @include aspect-ratio($ratio-21-9, 105%);

        grid-row-start: 1;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 4;
        background-color: $black;

        overflow: hidden;

        video {
            object-fit: cover;
            height: 100% !important;
            pointer-events: none;
        }

        &--media-filter {
            img,
            video {
                opacity: 0.8;
            }
        }
    }

    &__content {
        z-index: 2;
        overflow: hidden;
        width: 100%;
        max-width: $hero-content-max-width;
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 4;
        align-self: center;
        background-color: $hero-content-bg;

        @if $hero-content-position == 'center' {
            padding: 2rem;
            justify-self: center;
            text-align: center;
        } @else if $hero-content-position == 'left' {
            justify-self: flex-start;
            margin-left: $hero-content-margin-left;
            padding: $hero-content-padding;

            @include mobile {
                margin-left: 0;
                justify-self: center;
                max-width: calc(100vw - #{$gutter-small * 2});
            }
        }

        @include desktop {
            grid-row-start: 1;
            grid-row-end: 3;
        }
    }

    &__overlay {
        position: relative;
        z-index: 1;
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 4;
        opacity: 0.8;
        mix-blend-mode: overlay;
        background-image: linear-gradient(to top, $black, rgba($black, 0));
    }

    &__legal {
        position: relative;
        z-index: 2;
        grid-area: 2 / 1 / 2 / -1;
        padding: 0.5rem 1.6rem;

        > ncg-legal {
            padding-right: 0;

            .legal {
                display: inline;
            }
        }

        ncg-energy-label {
            svg {
                width: 35px;
                height: 12px;
            }
        }

        .legal {
            color: $hero-legal-color;

            b {
                font-size: inherit;
                font-weight: bold;
            }
        }

        &.legal-text-moved {
            @media (max-width: 768px) {
                grid-area: -1 / span 3;
                padding-bottom: 2em;

                > ncg-legal .legal {
                    @include when-inside('.grid__theme--default') {
                        color: $black;
                    }
                    @include when-inside('.grid__theme--light') {
                        color: $black;
                    }

                    b {
                        font-size: inherit;
                        font-weight: bold;
                    }
                }
            }
        }

        &.legal-text-moved-desktop {
            @include tablet {
                grid-area: -1 / span 3;
                padding-bottom: 2.5em;

                > ncg-legal .legal {
                    @include when-inside('.grid__theme--default') {
                        color: $black;
                    }
                    @include when-inside('.grid__theme--light') {
                        color: $black;
                    }

                    b {
                        font-size: inherit;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .extra-legal {
        display: flex;
    }

    .spot-content {
        &__header {
            &--title {
                margin-bottom: $hero-title-margin-bottom;
                font-family: map-get($font-families, $hero-title-family-key);
                font-size: map-get($font-sizes, $hero-title-default-size-key);
                font-weight: $hero-title-font-weight;
                line-height: map-get($line-heights, $hero-title-line-height-key);
                letter-spacing: map-get($letter-spacings, $hero-title-letter-spacing);
                text-transform: $hero-title-text-transform;
                word-break: $hero-title-word-break;

                @include desktop {
                    font-size: map-get($font-sizes, $hero-title-desktop-size-key);
                }

                @include fullhd {
                    font-size: map-get($font-sizes, $hero-title-fullhd-size-key);
                }

                &--has-long-word {
                    @include mobile {
                        font-size: $hero-title-long-word;
                    }
                }
            }

            &--subtitle {
                @include typeset($hero-subtitle-size-key, $hero-subtitle-family-key, $hero-subtitle-line-height-key, $hero-subtitle-weight-key);
                letter-spacing: map-get($letter-spacings, $hero-subtitle-letter-space-key);
                text-transform: $hero-subtitle-text-transform;
                margin-bottom: $hero-subtitle-margin-bottom;

                @include until($desktop) {
                    font-size: map-get($font-sizes, base);
                }
            }
        }
    }
}
