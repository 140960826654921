$expansion-panel-icon-color: inherit !default;

.expansion-panel {
    &__header {
        width: 100%;
        background-color: transparent;
        text-align: left;
        font-size: unset;
        font-family: inherit;
        line-height: inherit;
        letter-spacing: $letter-spacing-large;
    }

    &__icon {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        flex: 1;
        padding: 0.5rem 1rem 0.5rem 0;
        text-align: right;
        user-select: none;
        color: $expansion-panel-icon-color;
        background: transparent;
    }

    &__content {
        overflow: hidden;
    }

    @include tablet {
        &__icon {
            flex: 0 0 30%;
        }
    }
}
