@use 'sass:math';

/****** SWIPER *******/
@import 'swiper/scss';
@import 'swiper/scss/lazy';
@import 'swiper/scss/a11y';
@import 'swiper/scss/navigation';

$swiper-slide-bg: $white !default;
$swiper-bullet-bg: $grey-dark !default;
$swiper-bullet-bg-active: $primary !default;
$swiper-bullet-bg-active-dark: $white !default;
$swiper-button-bg: $white !default;
$swiper-button-size-base: 5.8rem !default;
$swiper-button-size-small: 4.4rem !default;
$swiper-button-size-x-small: 3rem !default;
$swiper-button-bg-secondary: $grey-lighter !default;

.swiper {
    .swiper-wrapper {
        box-sizing: border-box;

        .swiper-slide {
            background: $swiper-slide-bg;
            transform: translateZ(0); // Fixes flickering issue on safari - https://github.com/nolimits4web/swiper/issues/3527
        }
    }
}

// Applies to both bullets and fractions pagination
.swiper-pagination {
    position: absolute;
    z-index: $index-body-overlay;
}

.swiper-pagination-fraction {
    background-color: rgba($color: $black, $alpha: 0.7);
    color: $white;
    padding: 0.1rem 1.4rem;
    bottom: 2rem;
    right: 2rem;
    @include center-content;
    @include typeset(sub);
}

.swiper-pagination-bullets {
    left: 0;
    right: 0;
    bottom: 2.5rem;
    text-align: center;

    @include mobile {
        bottom: 1.5rem;
    }
}

.swiper-pagination-bullet {
    pointer-events: all;
    display: inline-block;
    flex-grow: 1;
    margin: 0 4px;
    padding: 1rem 0;
    cursor: pointer;

    @include mobile {
        padding: 0.5rem 0;
    }

    &::before {
        content: '';
        display: block;
        height: 0.4rem;
        min-width: 2rem;
        background-color: $swiper-bullet-bg;

        @include desktop {
            min-width: 3rem;
        }
    }

    &-active {
        &::before {
            background-color: $swiper-bullet-bg-active;

            @include when-inside('.grid__theme--dark') {
                background: $swiper-bullet-bg-active-dark;
            }
        }
    }
}

// Override default swiper-button styling
.swiper-button {
    &-prev,
    &-next {
        position: absolute;
        z-index: $index-body-overlay;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: var(--swiper-navigation-size);
        width: var(--swiper-navigation-size);
        margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
        cursor: pointer;
        pointer-events: all;
        background-color: $swiper-button-bg;
        border-radius: 50%;
        box-shadow: 0 0 2rem 0 rgba($black, 0.12);
        transition: opacity $duration-medium $smooth-in-out;

        &::after {
            font-size: 1.5rem;
            font-weight: bold;
        }

        @include mobile {
            --swiper-navigation-size: #{$swiper-button-size-small};
        }

        &.has-border {
            border: 1px solid $border;
        }

        &.is-small {
            --swiper-navigation-size: #{$swiper-button-size-small};
        }
    }
}

.swiper-button-container--secondary {
    .swiper-button {
        &-prev,
        &-next {
            --swiper-navigation-size: #{$swiper-button-size-small};
            background-color: $swiper-button-bg-secondary;
        }

        &-disabled {
            opacity: 0;
            transition: opacity 200ms cubic-bezier(0.885, 0, 0.68, 1);
        }
    }
}
