@use 'sass:math';

// Bulma variables
$input-height: $form-input-height;
$input-border-color: transparent !default;
$input-border-color-light: transparent !default;
$input-border-color-dark: transparent !default;
$input-background-color: $grey-lighter !default; // default for bulma
$input-background-color-light: $white !default;
$input-background-color-dark: $white !default;
$input-background-color-danger: false !default;
$input-arrow: $brownish-grey;
$input-color: $black !default; // default for bulma
$input-color-light: $black !default;
$input-color-dark: $white !default;
$input-shadow: none;
$input-placeholder-color: $grey-dark;
$input-icon-active-color: $primary;
$input-icon-color: $input-color;
$input-focus-border-color: $primary !default;
$input-focus-box-shadow-size: 0 0 2px !default;
$input-focus-box-shadow-color: transparent;
$input-focus-border-color-light: $primary !default;
$input-focus-border-color-dark: $black !default;
$input-hover-border-color: transparent !default;
$input-hover-border-color-light: transparent !default;
$input-hover-border-color-dark: transparent !default;
$input-radius: 0 !default;
$input-padding-vertical: 1.3rem !default;
$input-padding-horizontal: 1.6rem !default;
$input-arrow: transparent;
$input-hover-color: transparent;
$input-toggle-dot-size: 2rem !default;
$input-toggle-dot-color: $white !default;
$input-toggle-dot-color-active: $primary !default;
$input-toggle-bg-active: rgba($primary, 0.5) !default;
$input-toggle-bg-default: #bdc1c6 !default;
$input-toggle-width: 3.8rem !default;
$input-toggle-height: 1.5rem !default;
$input-toggle-padding: 0.1rem !default;
$input-hover-color: $primary;
$label-color: $grey-darker !default;
$label-text-transform: none !default;
$label-font-size: $font-size-base !default; // default for bulma
$label-font-family: map-get($font-families, medium) !default;
$label-weight: map-get($font-weights, base) !default;
$label-small-size-key: x-small !default;
$label-small-family-key: bold !default;
$label-letter-spacing-key: normal !default;
$help-letter-spacing-key: medium !default;
$label-letter-weight-key: normal !default;
$help-text-size: tiny !default;
$help-text-family: secondaryMedium !default;
$help-text-line-height: base !default;
$help-text-font-weight: normal !default;
$help-text-color: $text !default;
$field-size-key: base !default;
$field-family-key: base !default;
$field-line-height-key: base !default;
$field-letter-spacing-key: large !default;
$checkmark-border-color: transparent !default;
$checkmark-border-radius: 0 !default;
$checkmark-color: $primary !default;
$checkmark-checked-color: $primary !default;
$checkmark-background-color: $input-background-color !default;
$checkmark-checked-background-color: $input-background-color !default;
$checkmark-size: 2rem;
$checkmark-top-align: math.div($checkmark-size, 4) !default;

@import 'bulma/sass/form/all';

@mixin focus-styles {
    // TODO verify or simplify these are needed! creates massive selectors :(
    // &:focus,
    // &.is-focused,
    // &:active,
    // &.is-active {
    //     @content;
    // }

    &:focus {
        @content;
    }
}

@mixin hover-styles {
    &:hover,
    &.is-hovered {
        @content;
    }
}

%field-text {
    @include typeset($field-size-key, $field-family-key, $field-line-height-key);
    letter-spacing: map-get($letter-spacings, $field-letter-spacing-key);
}

%field-text-secondary {
    @include typeset(base, secondary, normal);
    letter-spacing: map-get($letter-spacings, large);
}

%help-text {
    @include typeset($help-text-size, $help-text-family, $help-text-line-height, $help-text-font-weight);

    &:not(.is-danger) {
        color: $help-text-color;
    }
}

%input-bg-color {
    background-color: $input-background-color;

    @include when-inside('.grid__theme--default') {
        background-color: $input-background-color;

        &:not(.is-danger) {
            border-color: $input-border-color;

            @include hover-styles {
                border-color: $input-hover-border-color;
            }

            @include focus-styles {
                border-color: $input-focus-border-color;
            }
        }
    }

    @include when-inside('.grid__theme--light') {
        background-color: $input-background-color-light;

        &:not(.is-danger) {
            border-color: $input-border-color-light;

            @include hover-styles {
                border-color: $input-hover-border-color-light;
            }

            @include focus-styles {
                border-color: $input-focus-border-color-light;
            }
        }
    }

    @include when-inside('.grid__theme--dark') {
        background-color: $input-background-color-dark;

        &:not(.is-danger) {
            border-color: $input-border-color-dark;

            @include hover-styles {
                border-color: $input-hover-border-color-dark;
            }

            @include focus-styles {
                border-color: $input-focus-border-color-dark;
            }
        }
    }

    @if $input-background-color-danger {
        &.is-danger {
            background-color: $input-background-color-danger;
        }
    }
}

.label {
    text-transform: $label-text-transform;
    font-size: $label-font-size;
    font-family: $label-font-family;
    letter-spacing: map-get($letter-spacings, $label-letter-spacing-key);
    font-weight: map-get($font-weights, $label-letter-weight-key);

    @include when-inside('.grid__theme--default') {
        color: $input-color;
    }

    @include when-inside('.grid__theme--light') {
        color: $input-color-light;
    }

    @include when-inside('.grid__theme--dark') {
        color: $input-color-dark;
    }

    &.is-small {
        font-size: map-get($font-sizes, $label-small-size-key);
        font-family: map-get($font-families, $label-small-family-key);
        line-height: map-get($line-heights, small);
    }
}

.input {
    border-radius: $input-radius;
    height: $input-height;
    padding: $input-padding-vertical $input-padding-horizontal;
}

.field-body > .field:not(:last-child) {
    @include from($tablet) {
        margin-right: 3rem;
    }
}

.field {
    position: relative;
    margin-bottom: 1.5rem;

    &:not(:last-child) {
        margin-bottom: 1.5rem;
    }

    .control {
        max-width: 100%;

        &-search {
            @include mobile {
                margin-right: 7.2rem;
            }
        }

        &.is-custom-disabled {
            cursor: not-allowed;

            > input {
                pointer-events: none;
            }
        }
    }

    &.is-grouped {
        &.is-compact {
            > .control {
                &:not(:last-child) {
                    margin-right: 1.8rem;
                }
            }
        }

        @include mobile {
            &:not(.is-not-column) {
                flex-direction: column;
            }

            > .control {
                &:not(:last-child) {
                    margin-right: 0;
                    margin-bottom: 1rem;
                }
            }

            &.is-compact {
                > .control {
                    &:not(:last-child) {
                        margin-right: 0;
                    }
                }
            }
        }

        @include tablet {
            > .control {
                &:not(:last-child) {
                    margin-right: 3rem;
                }
            }
        }
    }

    .is-grouped-multiline > .control {
        &:not(:last-child) {
            margin-bottom: 3rem;
        }

        &:last-child {
            margin-bottom: -3rem;
        }
    }

    &.is-grouped.is-grouped-multiline > .control:last-child,
    &.is-grouped.is-grouped-multiline > .control:not(:last-child) {
        margin-bottom: 2rem;
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;

        &.is-clickable {
            cursor: pointer;
            pointer-events: initial;
        }
    }

    .input {
        @extend .input;
    }

    .select {
        &.is-full {
            width: 100%;

            select {
                width: 100%;
            }
        }

        select {
            @extend %field-text;
            border-radius: $input-radius;
            height: $input-height;
            padding: $input-padding-vertical $input-padding-horizontal;

            &:not([multiple]) {
                padding-right: 4.9rem;
            }

            &:-moz-focusring {
                color: transparent;
                text-shadow: 0 0 0 $input-color; // MOZ hack to hide focus ring

                @include when-inside('.grid__theme--default') {
                    text-shadow: 0 0 0 $input-color; // MOZ hack to hide focus ring
                }

                @include when-inside('.grid__theme--light') {
                    text-shadow: 0 0 0 $input-color-light; // MOZ hack to hide focus ring
                }

                @include when-inside('.grid__theme--dark') {
                    text-shadow: 0 0 0 $input-color-dark; // MOZ hack to hide focus ring
                }
            }
        }

        &::after {
            width: 1.5rem !important;
            height: 0.9rem !important;
            margin-top: -0.4rem !important;
            margin-right: 0 !important;
            background-image: url('/assets/icons/select-arrow.svg');
            background-repeat: no-repeat;
            border: 0 !important;
            transform: rotate(0deg) !important;
        }
    }

    .input,
    .select select,
    .checkmark,
    .radio,
    .textarea {
        @extend %input-bg-color;

        &.is-disabled {
            cursor: not-allowed;
        }

        &[disabled] {
            // fix for Safari as disabled input does not propagating the click event up the DOM tree
            @include safari-only {
                pointer-events: none;
            }
        }
    }

    .textarea {
        border-radius: $input-radius;
        padding: $input-padding-vertical $input-padding-horizontal;
    }

    .input,
    .select,
    .textarea,
    .radio,
    .checkbox {
        @extend %field-text;

        &.is-small {
            font-size: map-get($font-sizes, sub);
        }
    }

    .help {
        @extend %help-text;

        letter-spacing: map-get($letter-spacings, $help-letter-spacing-key);
    }

    // Shared styling for checkbox and radio
    .checkbox,
    .radio {
        padding-left: 3rem;
        line-height: map-get($line-heights, base);
        background-color: transparent !important;

        &:not(:first-child) {
            margin-left: 5rem;
        }

        &:hover {
            @include when-inside('.grid__theme--default') {
                color: $input-color;
            }

            @include when-inside('.grid__theme--light') {
                color: $input-color-light;
            }

            @include when-inside('.grid__theme--dark') {
                color: $input-color-dark;
            }
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        // Create a custom checkbox
        .checkmark {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            background-color: $checkmark-background-color;
            border: $control-border-width solid $checkmark-border-color;
            border-radius: $checkmark-border-radius;
            height: $checkmark-size;
            width: $checkmark-size;

            &--top-aligned {
                top: $checkmark-top-align;
                transform: unset;
            }

            @include when-inside('.grid__theme--default') {
                background-color: $checkmark-background-color;
            }

            @include when-inside('.grid__theme--light') {
                background-color: $input-background-color-light;
            }

            @include when-inside('.grid__theme--dark') {
                background-color: $input-background-color-dark;
            }
        }

        // Create the checkmark/indicator (hidden when not checked)
        .checkmark::after {
            content: '';
            position: absolute;
            display: none;
            border-style: solid;
            border-color: $checkmark-color;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
        }

        // focus state
        input:focus-visible ~ .checkmark {
            outline: auto; // this one for fallback
            outline: -webkit-focus-ring-color auto 1px; // this one for default blue outline
        }

        // checked state
        input:checked ~ .checkmark {
            background-color: $checkmark-checked-background-color;

            @include when-inside('.grid__theme--light') {
                background-color: $checkmark-checked-background-color;
            }

            @include when-inside('.grid__theme--dark') {
                background-color: $white;
            }

            &::after {
                display: block;
                border-color: $checkmark-checked-color;

                @include when-inside('.grid__theme--light') {
                    border-color: $checkmark-checked-color;
                }

                @include when-inside('.grid__theme--dark') {
                    border-color: $primary;
                }
            }
        }
    }

    .radio {
        label {
            cursor: pointer;
        }

        &--hidden {
            padding-left: 0;
        }

        input:checked + label {
            color: $primary !important;
        }
    }

    // Seperate styling for checkbox
    .checkbox {
        // Style the checkmark/indicator
        .checkmark::after {
            left: 0.5rem;
            top: 0;
            width: 0.7rem;
            height: 1.4rem;
        }
    }

    // Seperate styling for radio buttons
    .radio {
        // Create a custom checkbox
        .checkmark {
            border-radius: 50%;
        }

        // Style the checkmark/indicator
        .checkmark::after {
            height: 1rem;
            width: 1rem;
            background-color: $checkmark-checked-color;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            border: 0;
        }

        @include when-inside('.grid__theme--dark') {
            .checkmark::after {
                background-color: $primary;
            }
        }
    }

    .toggle {
        display: flex;

        input {
            height: 0;
            width: 0;
            visibility: hidden;
        }

        label {
            position: relative;
            display: block;
            cursor: pointer;
            width: $input-toggle-width;
            height: $input-toggle-height;
            margin-right: 1rem;
            border-radius: $input-toggle-width;
            background-color: $input-toggle-bg-default;
            text-indent: -9999px;
            transition: background-color 100ms ease-in;
            transition-delay: 100ms;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: $input-toggle-dot-size;
                height: $input-toggle-dot-size;
                background-color: $input-toggle-dot-color;
                border-radius: 50%;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
                transform-origin: left;
                transform: translate3d(0, -0.3rem, 0);
                transition: transform $duration-fast ease-in;
            }

            &:focus::after,
            &:active::after {
                transform: scaleX(1.2) translateY(-0.3rem);
            }
        }

        input:checked + label {
            background-color: $input-toggle-bg-active;

            &::after {
                background-color: $input-toggle-dot-color-active;
                transform: scaleX(1) translate3d($input-toggle-width - $input-toggle-dot-size + 0.1rem, -0.3rem, 0);
            }

            &:focus::after,
            &:active::after {
                transform: scaleX(1.2) translate3d(1.18rem, -0.3rem, 0);
            }
        }
    }

    @include when-inside('.side-panel') {
        &.is-grouped.is-grouped-centered {
            justify-content: flex-start;
        }
    }
}

.newsletter {
    &__fieldset {
        @include mobile {
            display: flex;
            flex-direction: column;
        }
    }

    &__submit {
        @include from($tablet) {
            padding: 0;
        }

        @include mobile {
            order: 2;
        }
    }

    &__permission {
        @include mobile {
            order: 1;
        }
    }

    .column {
        @include until($tablet) {
            padding: 1rem !important;
        }

        &:not(:last-child) {
            @include from($tablet) {
                padding: 0 1.5rem 0 0;
            }
        }

        &.newsletter {
            &__submit {
                padding-right: 0;

                @include from($tablet) {
                    flex: 0 0 auto;
                }
            }
        }

        &.is-order-1 {
            order: 1;
        }

        &.is-order-2 {
            order: 2;
        }
    }

    .custom-select {
        .dropdown-menu {
            max-height: 25rem; // Not ideal, but it prevents dropdown to be hidden by another row due to z-index on rows.
        }
    }
}

.phone {
    display: flex;

    .phoneCode {
        flex-direction: column;
    }
}

.phoneOnly {
    width: 100%;
}
